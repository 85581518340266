import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

import { rhythm } from '../utils/typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGithub,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

class SocialLink extends React.Component {
  render() {
    const { href, icon } = this.props

    return (
      <a
        style={{
          color: `inherit`,
          padding: `0 5pt`,
        }}
        href={href}
      >
        <FontAwesomeIcon icon={icon} size={`lg`} />
      </a>
    )
  }
}

class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <StaticQuery
        query={baseLayoutQuery}
        render={data => {
          const { title, social } = data.site.siteMetadata
          return (
            <div>
              <div
                style={{
                  backgroundColor: `white`,
                  boxShadow: `0 2pt 2pt -2pt rgba(0, 0, 0, 0.2)`,
                  overflow: `hidden`,
                  padding: `${rhythm(1 / 4)} ${rhythm(3 / 4)}`,
                  width: `100%`,
                  display: `flex`,
                  justifyContent: `center`,
                }}
              >
                <div
                  style={{
                    maxWidth: rhythm(24),
                    width: `100%`,
                    display: `inline-flex`,
                    justifySelf: `center`,
                    alignItems: `center`,
                  }}
                >
                  <Link to={`/`}>
                    <h2 style={{ margin: 0 }}>{title}</h2>
                  </Link>
                  <span style={{ flexGrow: 1 }} />
                  <Link
                    to={`/projects`}
                    style={{
                      color: `inherit`,
                      paddingRight: `12pt`,
                    }}
                  >
                    <h4 style={{ margin: 0 }}>Projects</h4>
                  </Link>
                  <a
                    href={'/Resume.pdf'}
                    style={{
                      color: `inherit`,
                    }}
                  >
                    <h4 style={{ margin: 0 }}>Resume</h4>
                  </a>
                </div>
              </div>
              <div
                style={{
                  marginLeft: `auto`,
                  marginRight: `auto`,
                  maxWidth: rhythm(24),
                  padding: `0 ${rhythm(3 / 4)}`,
                }}
              >
                {children}
                <footer
                  style={{
                    display: `flex`,
                    flexDirection: `column`,
                    alignItems: `center`,
                    marginBottom: `20pt`,
                  }}
                >
                  <span style={{ padding: `10pt 0` }}>
                    <SocialLink
                      href={`https://github.com/${social.github}`}
                      icon={faGithub}
                    />
                    <SocialLink
                      href={`https://linkedin.com/in/${social.linkedin}`}
                      icon={faLinkedin}
                    />
                    <SocialLink
                      href={`https://twitter.com/${social.twitter}`}
                      icon={faTwitter}
                    />
                    <SocialLink
                      href={`mailto:${social.email}`}
                      icon={faEnvelope}
                    />
                  </span>
                  <span>© 2020 Eduardo Arenas</span>
                </footer>
              </div>
            </div>
          )
        }}
      />
    )
  }
}

const baseLayoutQuery = graphql`
  query BaseLayoutQuery {
    site {
      siteMetadata {
        title
        social {
          email
          github
          linkedin
          twitter
        }
      }
    }
  }
`

export default Layout
