import Typography from 'typography'
import Parnassus from 'typography-theme-parnassus'

Parnassus.headerWeight = 400
Parnassus.googleFonts = [
  {
    name: 'Merriweather Sans',
    styles: ['400'],
  },
  {
    name: 'Merriweather',
    styles: ['400', '400i', '700'],
  },
]
Parnassus.overrideThemeStyles = ({ rhythm }, options, styles) => ({
  a: {
    boxShadow: `none`,
  },
})

const typography = new Typography(Parnassus)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
